import { stringify } from 'query-string';

/**
  generatePath implementation from react-router
  https://github.com/remix-run/react-router/blob/eb90945c783596736dccead9bd861f2fb6157f51/packages/react-router/index.tsx#L755
**/
export const generatePath = (
  path: string,
  params: Params = {},
  queryParams?: QueryParams
): string => {
  const generatedPath = path
    .replace(/:(\w+)/g, (_, key) => {
      invariant(params[key] != null, `Missing ":${key}" param`);
      return params[key]!;
    })
    .replace(/\/*\*$/, () =>
      params['*'] == null ? '' : params['*'].replace(/^\/*/, '/')
    );

  return queryParams
    ? `${generatedPath}?${stringify(queryParams)}`
    : generatedPath;
};

export type Params = Record<string, string>;

export type QueryParams = Record<string, unknown>;

const invariant: (cond: unknown, message: string) => asserts cond = (
  cond: unknown,
  message: string
): asserts cond => {
  if (!cond) {
    throw new Error(message);
  }
};
