import type { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantSubtle: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  if (c === 'primary') {
    return {
      bg: 'primary.25',
      color: 'primary.600',
    };
  }

  if (c === 'critical') {
    return {
      bg: 'critical.100',
      color: 'critical.500',
    };
  }

  if (c === 'success') {
    return {
      bg: 'success.200',
      color: 'success.700',
    };
  }

  return {
    bg: `${c}.100`,
    color: `${c}.700`,
  };
};

const Badge = {
  baseStyle: {
    borderRadius: 'md',
    fontWeight: 'semibold',
    textTransform: 'none',
    px: 1.5,
  },
  sizes: {
    md: {
      padding: '4px 6px',
    },
  },
  variants: {
    subtle: variantSubtle,
  },
  defaultProps: {
    colorScheme: 'primary',
  },
};

export default Badge;
