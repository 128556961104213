import type { ShowProps as ChakraShowProps } from '@chakra-ui/react';
import { Show as ChakraShow } from '@chakra-ui/react';

import type { Breakpoint } from '../../themes/values';

export type ShowProps = Pick<ChakraShowProps, 'children' | 'ssr'> & {
  above: Breakpoint;
};

export const Show = ({ above, children, ssr = false }: ShowProps) => {
  return (
    <ChakraShow above={above} ssr={ssr}>
      {children}
    </ChakraShow>
  );
};
