export enum FeatureFlags {
  // Enables API rate limiting
  API_RATE_LIMIT = 'api-rate-limiting',
  // Enables enhancing document security checks in resolvers
  DOCUMENT_ACCESS_RESTRICTION = 'document-access-restriction',
  // Enables the schedule invoices
  SCHEDULE_INVOICES = 'schedule-invoices',
  // Enables the job to scrap linkedin users data
  LINKEDIN_USER_SCRAPING = 'linkedin-scraping',
  // To enable fetching shortlist session recordings
  SHORTLIST_SESSION_RECORDINGS_FETCHING = 'shortlist-session-recordings-fetching',
  // To enable slack notification for shortlist session recordings
  SHORTLIST_SESSION_RECORDING_NOTIFICATIONS = 'shortlist-session-recording-notifications',
  DISABLE_AUTOMATIC_CLIENT_GENERATION = 'disable-automatic-client-generation',
  // Enables the job to scrap linkedin companies data
  LINKEDIN_COMPANY_SCRAPING = 'linked-in-company-scrapping',
  // Enables social feed
  SOCIAL_FEED = 'social-feed',
  // Enables solo invoice collab
  SOLO_INVOICE_COLLAB = 'solo-invoice-collab',
  // Show explain button in megasearch
  SHOW_EXPLAIN = 'show-explain',
  // Enables the recruiter job notifications
  JOB_NOTIFICATIONS = 'job-notifications',
  // Enables solo clients view
  SOLO_CLIENTS_VIEW = 'solo-clients-view',
  // Enables freelancer job board
  FREELANCER_JOB_BOARD = 'freelancer-job-board',
}
