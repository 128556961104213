export enum ExpectationsValues {
  TOOLING = 'TOOLING',
  MISSIONS = 'MISSIONS',
  COLLECTIVE = 'COLLECTIVE',
  NETWORK = 'NETWORK',
  TEAM_PAYMENTS = 'TEAM_PAYMENTS',
  TRY_APP = 'TRY_APP',
  OTHER = 'OTHER',
}

export enum ExperienceValues {
  THINKING_STARTING = 'THINKING_STARTING',
  STARTED = 'STARTED',
  LESS_ONE_YEAR = 'LESS_ONE_YEAR',
  ONE_THREE_YEARS = 'ONE_THREE_YEARS',
  MORE_THAN_THREE_YEARS = 'MORE_THAN_THREE_YEARS',
}
