export const LINKEDIN_BASE_URL = 'https://www.linkedin.com/in';

export const LINKEDIN_URL_REGEX = /linkedin\.[\w\d.]+\/in\/([^?/]+)/;

export const LINKEDIN_TEST_PREFIX = 'datadog-e2e-test';

export const getLinkedInHandle = (
  url: string | null | undefined
): string | null => {
  const match = url?.match(LINKEDIN_URL_REGEX);

  return match ? match[1] : null;
};

export const normalizeLinkedInUrl = (
  url: string | null | undefined
): string | null => {
  const handle = getLinkedInHandle(url);

  return handle ? `https://linkedin.com/in/${handle}` : null;
};

export const getLinkedInUrlFromHandle = (handle: string) => {
  return `${LINKEDIN_BASE_URL}/${handle}`;
};

export const isLinkedInTestUser = (handle: string) => {
  return handle.startsWith(LINKEDIN_TEST_PREFIX);
};
