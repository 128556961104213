const wait = (timeMs: number) =>
  new Promise((resolve) => setTimeout(resolve, timeMs));

type PromiseRetryOptions<T> = {
  promiseFn: () => Promise<T>;
  retries: number;
  delay?: number;
  backoff?: number;
};

export const promiseRetry = async <T>(
  options: PromiseRetryOptions<T>
): Promise<T> => {
  const { promiseFn, retries, delay = 1000, backoff = 1 } = options || {};

  try {
    return await promiseFn();
  } catch (e) {
    if (retries === 0) {
      throw e;
    }

    await wait(delay);
    return promiseRetry({
      promiseFn,
      retries: retries - 1,
      delay: delay * backoff,
      backoff,
    });
  }
};

export const isPromiseRejected = <T>(
  input: PromiseSettledResult<T>
): input is PromiseRejectedResult => input.status === 'rejected';
