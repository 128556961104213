import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  forwardRef,
} from '@chakra-ui/react';

import { ButtonSize, ButtonVariant } from '../../themes/components/button';

export interface ButtonProps extends ChakraButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  // use 'isDisabled' instead. just using 'disabled' does not work as before https://github.com/chakra-ui/chakra-ui/issues/7243#issuecomment-1383939865
  disabled?: never;
}

export const Button = forwardRef<ButtonProps, 'button'>(function Button(
  { size, children, ...rest },
  ref
) {
  return (
    <ChakraButton
      ref={ref}
      iconSpacing={children ? (size === 'sm' ? 2 : 3) : 0}
      size={size}
      minWidth={children ? undefined : 0}
      paddingInline={children ? undefined : '3px'}
      spinnerPlacement="end"
      {...rest}
    >
      {children}
    </ChakraButton>
  );
});
