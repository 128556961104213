import {
  BoxProps,
  InputProps,
  List,
  ListProps,
  useBoolean,
  useMergeRefs,
} from '@chakra-ui/react';
import { InMemorySearch } from '@collective/utils/helpers';
import {
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useClickAway } from 'react-use';

import { IconSearch } from '../../icon/icon';
import { BorderBox, Box } from '../../layout';
import { SelectOption } from '../select/select-option';
import { TextInput } from '../text-input';

type Item = {
  label: string;
  isDisabled?: boolean;
};

const defaultItemToString = (item: Item) => {
  return item.label;
};

export type SearchBarProps<T extends Item> = {
  items?: T[];
  value?: T;
  placeholder?: string;
  threshold?: number;
  onSelection?: (selectedItem: T) => void;
  onSearch?: (search: string) => void;
  getNoResult?: (search: string) => ReactNode;
  getCustomOption?: (search: string) => ReactNode;
  itemToString?: (item: Item) => string;
  displayItem?: (item: T) => ReactNode;
  wrapperProps?: BoxProps;
  dropdownProps?: BoxProps;
  selectorWidth?: string;
  dropdownWidth?: string;
  shouldShowDropdownScrollbar?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  maxHeight?: string | number;
  iconColorActive?: string;
  menuStyle?: ListProps;
  dropdownPosition?: 'top' | 'bottom';
  forceOpenOnFocus?: boolean;
  inputProps?: InputProps;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
};

const DEFAULT_THRESHOLD = 0.4;

const SearchBarInner = <T extends Item>(
  {
    items = [],
    placeholder,
    threshold = DEFAULT_THRESHOLD,
    onSelection,
    onSearch,
    getNoResult,
    itemToString = defaultItemToString,
    displayItem,
    wrapperProps,
    dropdownProps,
    isReadOnly = false,
    isDisabled = false,
    dropdownWidth = '100%',
    shouldShowDropdownScrollbar = true,
    maxHeight = '300px',
    iconColorActive = 'rythm.900',
    menuStyle,
    dropdownPosition,
    forceOpenOnFocus = false,
    inputProps = {},
    leftElement = <IconSearch color="rythm.600" />,
    rightElement = null,
    getCustomOption,
  }: SearchBarProps<T>,
  forwardedRef: React.ForwardedRef<HTMLInputElement>
) => {
  const [filteredItems, setFilteredItems] = useState<T[]>([]);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useBoolean(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const refs = useMergeRefs(containerRef, forwardedRef);

  useClickAway(containerRef, () => {
    setIsOpen.off();
  });

  useEffect(() => {
    if (forceOpenOnFocus && inputRef.current === document.activeElement) {
      setIsOpen.on();
      return;
    }

    if (search) {
      setIsOpen.on();
    } else {
      setIsOpen.off();
    }
  }, [forceOpenOnFocus, search, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      const inMemorySearch = new InMemorySearch(items, { keys: ['label'] });

      setFilteredItems(
        search && !onSearch ? inMemorySearch.query(search) : items
      );
      setHighlightedIndex(-1);
    }
  }, [isOpen, search, items, threshold, onSearch]);

  const onItemClick = useCallback(() => {
    setIsOpen.off();
    setSearch('');
    setHighlightedIndex(-1);
  }, [setIsOpen, setSearch, setHighlightedIndex]);

  const selectItem = useCallback(
    (item: T) => {
      if (item.isDisabled) {
        return;
      }

      onSelection?.(item);
      onItemClick();
      onSearch?.('');
    },
    [onSelection, onItemClick, onSearch]
  );

  const onKeyDownList = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (
        e.code === 'Enter' &&
        highlightedIndex !== -1 &&
        filteredItems.length
      ) {
        e.preventDefault();
        e.stopPropagation();
        selectItem(filteredItems[highlightedIndex]);

        return;
      }
      if (e.code === 'ArrowDown' && isOpen) {
        e.preventDefault();
        const value =
          highlightedIndex === -1 ||
          highlightedIndex + 1 >= filteredItems.length
            ? 0
            : highlightedIndex + 1;

        setHighlightedIndex(value);
      } else if (e.code === 'ArrowUp' && isOpen) {
        e.preventDefault();
        const value =
          highlightedIndex === -1 || highlightedIndex - 1 < 0
            ? filteredItems.length - 1
            : highlightedIndex - 1;

        setHighlightedIndex(value);
      }
    },
    [highlightedIndex, filteredItems, isOpen, selectItem]
  );

  return (
    <Box
      position="relative"
      w="100%"
      {...wrapperProps}
      ref={refs}
      sx={{
        // To deal with the hover state if the mouse is on the icons
        '.chakra-input__left-element': { pointerEvents: 'none' },
        '.chakra-input__right-element': { pointerEvents: 'none' },
      }}
    >
      <TextInput
        {...inputProps}
        value={search}
        onChange={(e) => {
          const value = e.target.value;
          setSearch(value);
          onSearch?.(value);
        }}
        leftElement={leftElement}
        rightElement={rightElement}
        placeholder={placeholder}
        layerStyle={
          isDisabled
            ? 'disabledSelectButton'
            : isReadOnly
            ? 'readOnlySelectButton'
            : 'selectButton'
        }
        onFocus={() => {
          if (search || forceOpenOnFocus) {
            setIsOpen.on();
          }
        }}
        onKeyDown={onKeyDownList}
        sx={{
          '&:focus, &[aria-expanded="true"]': {
            borderColor: 'primary.600',
            '& + div svg.non-legacy-icon path': {
              stroke: iconColorActive,
              transition: '.1s ease-in-out stroke',
            },
            '& + div svg.legacy-icon path': {
              fill: iconColorActive,
              transition: '.1s ease-in-out fill',
            },
          },
          '&:hover + div svg.non-legacy-icon path': {
            stroke: iconColorActive,
            transition: '.1s ease-in-out stroke',
          },
          '&:hover + div svg.legacy-icon path': {
            fill: iconColorActive,
            transition: '.1s ease-in-out fill',
          },
        }}
        disabled={isReadOnly || isDisabled}
        ref={inputRef}
      />
      <List {...menuStyle}>
        <BorderBox
          position="absolute"
          width={dropdownWidth}
          boxShadow="md"
          zIndex={10}
          my={1}
          display={isOpen ? 'block' : 'none'}
          _hover={{}}
          bottom={dropdownPosition === 'top' ? '100%' : undefined}
          {...dropdownProps}
        >
          {/* This box is to contain and display a scrollbar in a decent manner */}
          <Box
            {...(shouldShowDropdownScrollbar && {
              overflowY: 'auto',
              maxHeight,
            })}
            mr="-1px"
            pr="1px"
            py={2}
            onMouseOver={() => setHighlightedIndex(-1)}
          >
            {isOpen &&
              (filteredItems.length ? (
                filteredItems.map((item, index) => {
                  const label = itemToString(item);
                  const displayedLabel = displayItem
                    ? displayItem(item)
                    : label;

                  return (
                    <SelectOption
                      key={`${label}${index}`}
                      className="search-bar__select-option"
                      item={item}
                      index={index}
                      selectedItem={undefined}
                      highlightedIndex={highlightedIndex}
                      getItemProps={() => {}}
                      onClick={() => selectItem(item)}
                    >
                      {displayedLabel}
                    </SelectOption>
                  );
                })
              ) : (
                <Box onClick={onItemClick}>{getNoResult?.(search)}</Box>
              ))}
            {isOpen && getCustomOption && (
              <Box onClick={onItemClick}>{getCustomOption(search)}</Box>
            )}
          </Box>
        </BorderBox>
      </List>
    </Box>
  );
};

export const SearchBar = forwardRef(SearchBarInner);
