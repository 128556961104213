const Modal = {
  parts: ['modal', 'dialog'],
  sizes: {
    sm: {
      dialog: {
        maxWidth: '352px',
      },
    },
    md: {
      dialog: {
        maxWidth: '608px',
      },
    },
    lg: {
      dialog: {
        maxWidth: '800px',
      },
    },
    xl: {
      dialog: {
        maxWidth: '1024px',
      },
    },
  },
};

export default Modal;
