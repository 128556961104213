import { sanitize } from '@collective/utils/helpers';
import dayjs from 'dayjs';
import i18n, {
  StringMap,
  TFunctionKeys,
  TFunctionResult,
  ThirdPartyModule,
  TOptions,
} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ENGLISH_BACKEND from './backend/en.json';
import FRENCH_BACKEND from './backend/fr.json';
import ENGLISH_COMMON from './common/en.json';
import FRENCH_COMMON from './common/fr.json';
import ENGLISH_EMAILS from './emails/en.json';
import FRENCH_EMAILS from './emails/fr.json';
import ENGLISH_FRONTEND from './frontend/en.json';
import FRENCH_FRONTEND from './frontend/fr.json';
import { SUPPORTED_LANGUAGES, supportedLanguages } from './languages';
import ENGLISH_PUBLIC_PAGES from './public-pages/en.json';
import FRENCH_PUBLIC_PAGES from './public-pages/fr.json';

export type TranslationNamespace =
  | 'emails'
  | 'frontend'
  | 'common'
  | 'backend'
  | 'public-pages';

// https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
type InitI18nType = {
  ns?: TranslationNamespace;
  fallbackNs?: TranslationNamespace[];
  enableLanguageDetection?: boolean;
  initReactI18next?: ThirdPartyModule;
  language?: string | null;
};

export function initI18n({
  ns = undefined,
  fallbackNs = undefined,
  enableLanguageDetection = false,
  initReactI18next = undefined,
  language = null,
}: InitI18nType) {
  if (language) {
    dayjs.locale(language);
  }
  // if i18n is not initialized, initialize it
  if (i18n.isInitialized) {
    return i18n;
  }
  if (enableLanguageDetection) {
    i18n.use(LanguageDetector);
  }
  if (initReactI18next) {
    i18n.use(initReactI18next);
  }

  i18n.on('languageChanged', (lng) => {
    dayjs.locale(lng);
  });

  i18n.init({
    fallbackLng: 'en',
    supportedLngs: SUPPORTED_LANGUAGES,
    ns: ['backend', 'emails', 'common', 'frontend', 'public-pages'],
    defaultNS: ns,
    fallbackNS: fallbackNs,
    interpolation: { escape: sanitize },
    resources: {
      [supportedLanguages.en]: {
        ['emails']: ENGLISH_EMAILS,
        ['backend']: ENGLISH_BACKEND,
        ['common']: ENGLISH_COMMON,
        ['frontend']: ENGLISH_FRONTEND,
        ['public-pages']: ENGLISH_PUBLIC_PAGES,
      },
      [supportedLanguages.fr]: {
        ['emails']: FRENCH_EMAILS,
        ['backend']: FRENCH_BACKEND,
        ['common']: FRENCH_COMMON,
        ['frontend']: FRENCH_FRONTEND,
        ['public-pages']: FRENCH_PUBLIC_PAGES,
      },
    },
    returnEmptyString: false,
    ...(language ? { lng: language } : {}),
  });

  // The i18n instance needs to be passed to Storybook to allow locale switching
  return i18n;
}

export function getTranslator(
  ns: TranslationNamespace | TranslationNamespace[],
  languages?: string | null
) {
  return <
    TResult extends TFunctionResult = string,
    TKeys extends TFunctionKeys = string,
    TInterpolationMap extends object = StringMap
  >(
    key: TKeys | TKeys[],
    options: TOptions<TInterpolationMap> = {} as TInterpolationMap
  ): TResult => {
    return i18n.t(key, {
      ns,
      lng: languages,
      ...options,
    });
  };
}
