import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
  Flex,
  ResponsiveObject,
  TooltipProps,
  useImage,
} from '@chakra-ui/react';
import { randomColor } from '@chakra-ui/theme-tools';
import { isNil } from 'lodash';
import { forwardRef, ReactNode } from 'react';

import { useBreakpointValue } from '../../../hooks/media-query/use-breakpoint';
import {
  AvatarSize,
  avatarWithBorderProperties,
} from '../../../themes/components/avatar';
import { Box } from '../../layout/box';
import { Link } from '../../link/link';
import { Skeleton } from '../../skeleton/skeleton';
import { Tooltip } from '../../tooltip/tooltip';

export type AvatarTooltipProps = Omit<TooltipProps, 'children'>;

export interface AvatarProps extends Omit<ChakraAvatarProps, 'src' | 'name'> {
  size?: ResponsiveObject<AvatarSize> | AvatarSize;
  color?: string;
  withTooltip?: boolean;
  tooltipLabel?: string | ReactNode;
  link?: string;
  tooltipProps?: AvatarTooltipProps;
  shouldSanitizeName?: boolean;
  src?: string | null;
  name?: string | null;
  withSkeleton?: boolean;
}

const DEFAULT_SIZE = 'sm';

export const Avatar = forwardRef<HTMLSpanElement, AvatarProps>(function Avatar(
  {
    children,
    size = DEFAULT_SIZE,
    withTooltip,
    tooltipLabel,
    tooltipProps,
    link,
    showBorder,
    borderColor,
    name,
    src,
    color,
    shouldSanitizeName = true,
    withSkeleton = false,
    ...rest
  },
  ref
) {
  const _src = src || undefined;
  /**
   * use the image hook to only show the image when it has loaded
   */
  const status = useImage({ src: _src });
  const hasLoaded = status === 'loaded';
  const showFallback = !src || !hasLoaded;
  const showSkeleton = withSkeleton && !!src && !hasLoaded;

  const label = withTooltip ? tooltipLabel || name : '';

  const avatarBg =
    color ||
    (name
      ? randomColor({
          string: name,
          colors: ['primary.300', 'primary.600', 'primary.700', 'primary.800'],
        })
      : 'rythm.300');

  const currentSize = useCurrentSize(size);

  const borderProperties = avatarWithBorderProperties[currentSize];

  const avatar = (
    <ChakraAvatar
      ref={ref}
      size={size}
      name={name || undefined}
      getInitials={(name: string) => {
        const sanitizedName = sanitizeName(shouldSanitizeName, name);
        return sanitizedName && sanitizedName[0];
      }}
      bg={showFallback ? avatarBg : 'transparent'}
      src={_src}
      data-chromatic="ignore"
      sx={{
        '> img': {
          borderRadius: 0,
        },
        // border radius is different between border and borderless version, that's why we need this
        ...(showBorder &&
          'borderRadius' in borderProperties.container && {
            borderRadius: borderProperties.container.borderRadius,
          }),
      }}
      {...(src && { 'data-chromatic': 'ignore' })}
      {...rest}
    >
      {children}

      {showSkeleton && (
        <Box position="absolute" top={0} left={0} w="100%" h="100%" bg="white">
          <Skeleton w="100%" h="100%" />
        </Box>
      )}
    </ChakraAvatar>
  );

  const avatarWithWrapper = link ? (
    <Link target="blank" href={link} onClick={(e) => e.stopPropagation()}>
      {avatar}
    </Link>
  ) : (
    <>{avatar}</>
  );

  return (
    <Tooltip label={label} {...tooltipProps}>
      {showBorder ? (
        /**
         * we don't use the border effect given by chakra because:
         * it reduces the size of the avatar to include the border
         * it doesn't support linear gradients as a border color
         */
        <Flex
          alignItems="center"
          justifyContent="center"
          {...borderProperties.firstBoxStyle}
          minW={borderProperties.firstBoxStyle.width}
          background={borderColor}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            {...borderProperties.secondBoxStyle}
            minW={borderProperties.secondBoxStyle.width}
            background="white"
          >
            {avatarWithWrapper}
          </Flex>
        </Flex>
      ) : (
        avatarWithWrapper
      )}
    </Tooltip>
  );
});

const useCurrentSize = (size: AvatarProps['size']): AvatarSize => {
  const value = useBreakpointValue(
    typeof size === 'object' ? size : { base: size }
  );

  return value || DEFAULT_SIZE;
};

const sanitizeName = (shouldSanitize: boolean, name?: string | null) => {
  const _name = isNil(name) ? '' : name;

  if (!shouldSanitize) {
    return _name.trim();
  }

  return _name
    .replace('[Test]', '')
    .replace(/[^\s\-a-zA-Z0-9?+]/g, '')
    .trim();
};
