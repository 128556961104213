import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';
import { cloneElement, forwardRef } from 'react';

import {
  ButtonSize,
  ButtonVariant,
  IconButtonSize,
} from '../../themes/components/button';
import { IconSize } from '../icon/icon';

export interface IconButtonProps extends ChakraIconButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  // use 'isDisabled' instead. just using 'disabled' does not work as before https://github.com/chakra-ui/chakra-ui/issues/7243#issuecomment-1383939865
  disabled?: never;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton({ size = 'md', icon, ...rest }, ref) {
    const iconButtonSizeMapping: Record<ButtonSize, IconButtonSize> = {
      sm: 'iconSm',
      md: 'iconMd',
      lg: 'iconLg',
    };

    const iconSizeMapping: Record<ButtonSize, IconSize> = {
      sm: 'xs',
      md: 'md',
      lg: 'md',
    };

    return (
      <ChakraIconButton
        ref={ref}
        size={iconButtonSizeMapping[size] ?? size}
        minW="auto"
        icon={
          icon && !icon.props.size
            ? cloneElement(icon, {
                size: iconSizeMapping[size],
              })
            : icon
        }
        {...rest}
      />
    );
  }
);
