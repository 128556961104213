import { ManipulateType, OpUnitType } from 'dayjs';

import dayjs from './dayjs';

export const DATE_FORMAT_FR = 'DD/MM/YYYY';
export const DATE_FORMAT_EN = 'DD/MM/YYYY';
export const DATE_FORMAT_INVOICE = 'DD/MM/YYYY';
export const DATE_FORMAT_MONTH_YEAR = 'MMMM YYYY';
export const DATE_MINI_FORMAT_FR = 'D MMMM';
export const DATE_MINI_FORMAT_EN = 'MMMM D';
export const TIME_FORMAT_FR = 'H:mm';
export const TIME_FORMAT_EN = 'h:mma';
export type DateOrString = Date | string;

export function isValid(date: DateOrString): boolean {
  return dayjs(date).isValid();
}

export function toReadableDate(date: DateOrString, language?: string) {
  const format = 'll';
  if (language) {
    return dayjs(date).locale(language).format(format);
  }
  return dayjs(date).format(format);
}

export function toReadableDateTime(date: DateOrString, language?: string) {
  const format = `dddd ll ${getTimeFormat()}`;
  if (language) {
    return dayjs(date).locale(language).format(format);
  }
  return dayjs(date).format(format);
}

export function toReadableDateFullMonth(date: DateOrString) {
  return dayjs(date).format('D MMMM YYYY');
}

export function timeFromDate(
  startDate: DateOrString,
  endDate: DateOrString,
  withoutSuffix = true
): string | null {
  if (!isValid(startDate) || !isValid(endDate)) {
    return null;
  }
  return dayjs(startDate).from(dayjs(endDate), withoutSuffix);
}

export function getStartOf(date: DateOrString, unit: OpUnitType): Date | null {
  if (!isValid(date)) {
    return null;
  }
  return dayjs(date).startOf(unit).toDate();
}

export function getCurrentDate() {
  return new Date();
}

export function getDateIn(
  date: Date,
  amount: number,
  unit: ManipulateType
): Date;
export function getDateIn(
  date: string,
  amount: number,
  unit: ManipulateType
): Date | null;
export function getDateIn(
  date: DateOrString,
  amount: number,
  unit: ManipulateType
): Date | null {
  if (!isValid(date)) {
    return null;
  }
  return dayjs(date).add(amount, unit).toDate();
}

export function isExpired(date: DateOrString): boolean {
  return dayjs().isAfter(date);
}

export function parseLocalizedDate(str: string): Date | null {
  const parsed = dayjs(str, 'L', true);
  if (!parsed.isValid()) {
    return null;
  }
  return parsed.toDate();
}

export function getUtcDateFromString(date: string): Date | null {
  const parsed = dayjs.utc(date, 'L', true);
  if (!parsed.isValid()) {
    return null;
  }

  return parsed.toDate();
}

export function getLocalizedPlaceholder(): string {
  return dayjs().localeData().longDateFormat('L');
}

export function getLocalizedDateMask(): string {
  return dayjs().localeData().longDateFormat('mask');
}

export function toLocaleDate(
  date: DateOrString,
  forceLocale?: string
): string | null {
  if (!isValid(date)) {
    return null;
  }

  const locale = forceLocale || dayjs.locale();

  switch (locale) {
    case 'en':
      return dayjs(date).format(DATE_FORMAT_EN);
    case 'fr':
      return dayjs(date).format(DATE_FORMAT_FR);
    default:
      return dayjs(date).format(DATE_FORMAT_EN);
  }
}

export function toInputDate(date: DateOrString): string | null {
  return dayjs(date).format('L');
}

export function toInvoiceDate(
  date: DateOrString | null | undefined
): string | null {
  if (!date || !isValid(date)) {
    return null;
  }
  return dayjs(date).format(DATE_FORMAT_INVOICE);
}

export function diff(
  date1: DateOrString,
  date2: DateOrString,
  unit?: OpUnitType,
  float?: boolean
): number {
  const dayjs1 = dayjs(date1);
  const dayjs2 = dayjs(date2);
  return dayjs1.diff(dayjs2, unit, float);
}

export function daysDiff(date1: DateOrString, date2: DateOrString): number {
  const dayjs1 = dayjs(date1).startOf('day');
  const dayjs2 = dayjs(date2).startOf('day');

  return dayjs1.diff(dayjs2, 'days', false);
}

export function toExpiryDate(date: DateOrString) {
  return dayjs(date).hour(23).minute(59).second(59).toDate();
}

export function isOverlaping(
  period1: [DateOrString, DateOrString],
  period2: [DateOrString, DateOrString],
  unit: OpUnitType = 'day'
) {
  const [startDate1, endDate1] = period1;
  const [startDate2, endDate2] = period2;

  return (
    dayjs(startDate1).isBetween(startDate2, endDate2, unit) ||
    dayjs(endDate1).isBetween(startDate2, endDate2, unit) ||
    dayjs(startDate2).isBetween(startDate1, endDate1, unit) ||
    dayjs(endDate2).isBetween(startDate1, endDate1, unit) ||
    dayjs(startDate1).isSame(dayjs(startDate2)) ||
    dayjs(endDate1).isSame(dayjs(endDate2)) ||
    dayjs(startDate1).isSame(dayjs(endDate2)) ||
    dayjs(startDate2).isSame(dayjs(endDate1))
  );
}

export function dateToEpochTimeSeconds(date: Date) {
  return Math.round(date.getTime() / 1000);
}

export function convertToUTC(date: Date, keepHour = true) {
  return dayjs(date).utc(keepHour).format();
}

export function parseStringToDayJS(dateString: string, mask?: string) {
  return mask ? dayjs(dateString, mask) : dayjs(dateString);
}

export function isToday(date: DateOrString) {
  return daysDiff(new Date(date), new Date()) === 0;
}

export function getShortDateFormat() {
  const locale = dayjs.locale();
  switch (locale) {
    case 'fr':
      return DATE_MINI_FORMAT_FR;
    default:
      return DATE_MINI_FORMAT_EN;
  }
}
export function getTimeFormat() {
  const locale = dayjs.locale();
  switch (locale) {
    case 'fr':
      return TIME_FORMAT_FR;
    default:
      return TIME_FORMAT_EN;
  }
}
