import { ReactQueryProvider } from '@collective/data-access/clients/react-query-provider';
import { theme, UIProvider } from '@collective/ui';
import {
  initDatadogUserMonitoring,
  initLogging,
} from '@collective/utils/frontend';
import { LD_ANONYMOUS_KEY } from '@collective/utils/helpers';
import { datadogRum } from '@datadog/browser-rum';
import { Hydrate } from '@tanstack/react-query';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';

import { FeatureFlagsProvider } from '~/public-pages/contexts/feature-flags-provider';
import { useKeyboardEventPropagate } from '~/public-pages/hooks/use-keyboard-event-propagate';
import { getI18nInstance } from '~/public-pages/i18n';
import { LaunchdarklyFlags } from '~/public-pages/types/launchdarkly';
import { TrackingProps } from '~/public-pages/types/tracking';
import { getFirstItemIfArray } from '~/public-pages/utils/parsing';

type LayoutProps = AppProps<
  TrackingProps & {
    dehydratedState?: unknown;
    initialFeatureFlags?: LaunchdarklyFlags;
    language?: string;
  }
>;

const Layout = ({ Component, pageProps }: LayoutProps) => {
  const { query, locale } = useRouter();

  const i18nInstance = useMemo(() => {
    const languageToUse =
      pageProps.language || locale || getFirstItemIfArray(query.language);

    return getI18nInstance(languageToUse);
  }, [locale, query.language, pageProps.language]);

  const { gtmId, datadogService, datadogApplicationId } = pageProps;

  useEffect(() => {
    initLogging({ service: datadogService || undefined });
    if (datadogApplicationId) {
      initDatadogUserMonitoring({
        applicationId: datadogApplicationId,
        service: datadogService || undefined,
      });
      datadogRum.startSessionReplayRecording();
    }

    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- switch from warn to error
  }, [gtmId]);
  useKeyboardEventPropagate();

  return (
    <FeatureFlagsProvider initialFlags={pageProps.initialFeatureFlags}>
      <style global jsx>{`
        body,
        div#__next,
        main {
          height: 100%;
        }
      `}</style>
      <UIProvider theme={theme}>
        <ReactQueryProvider>
          <Head>
            <title>Collective</title>
          </Head>
          <main>
            <IntercomProvider appId={process.env.NX_INTERCOM_APP_ID ?? ''}>
              <I18nextProvider i18n={i18nInstance}>
                {/* To optimize react-queries following : https://dev.to/arianhamdi/react-query-v4-ssr-in-next-js-2ojj */}
                <Hydrate state={pageProps.dehydratedState}>
                  <Component {...pageProps} />
                </Hydrate>
              </I18nextProvider>
            </IntercomProvider>
          </main>
        </ReactQueryProvider>
      </UIProvider>
    </FeatureFlagsProvider>
  );
};

export default withLDProvider({
  clientSideID: process.env.NX_LAUNCHDDARKLY_CLIENT_SIDE_ID ?? '',
  context: {
    kind: 'user',
    // use a shared key for anonymous users to not have them increase our
    // usage and ou billing
    // https://docs.launchdarkly.com/home/users/anonymous-users#tracking-anonymous-users-with-a-shared-key
    key: LD_ANONYMOUS_KEY,
    custom: {
      environment: process.env.NX_LAUNCHDARKLY_ENV || '',
    },
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    bootstrap: 'localStorage', // use local storage by default
    streaming: true,
  },
  // @ts-expect-error didn't manage to type withLDProvider to get rid of it
})(Layout);
