import utilsDate from '@collective/utils/date';
import {
  cleanEmail,
  getRichEditorStrippedValue,
  isEmptyRichTextValue,
  isValidCardNumber,
  parseDecimal,
  passwordSchema,
} from '@collective/utils/helpers';
import { SEPA_LIST } from '@collective/utils/shared';
import { OpUnitType } from 'dayjs';
import Decimal from 'decimal.js';
import { FieldValidator } from 'final-form';
import i18next from 'i18next';
import { CountryCode, isValidNumberForRegion } from 'libphonenumber-js';
import validator from 'validator';

/**
 * This is used only when i18n is not loaded (usually in unit/component tests)
 * to avoid `message` variables to be undefined
 * thus the functions to be valid when there are in fact errors
 */
const DEFAULT_ERROR_MESSAGE = 'Error';

/**
 * Make value go through a serie of validators
 * @param validators
 */
export const pipeValidators = <ValueType = string>(
  ...validators: FieldValidator<ValueType>[]
) => {
  return (...args: Parameters<FieldValidator<ValueType>>) => {
    for (const validator of validators) {
      const err = validator(...args);

      if (err) {
        return err;
      }
    }
  };
};

/**
 * Make each value of an Array ValueType go through a validator
 * @param validator
 */
export const each =
  <ValueType = string>(validator: FieldValidator<ValueType>) =>
  (...rest: Parameters<FieldValidator<ValueType[]>>) => {
    const [values, allValues] = rest;
    for (const value of values) {
      const error = validator(value, allValues);
      if (error) {
        return error;
      }
    }
  };

export const isRequired =
  <ValueType = string>(
    message = i18next.t('validatorMessages.isRequired', { ns: 'common' }) ||
      DEFAULT_ERROR_MESSAGE
  ) =>
  (value: ValueType) => {
    if (Array.isArray(value) && !value.length) {
      return message;
    }
    if (typeof value === 'string' && value.trim() === '') {
      return message;
    }
    if (typeof value === 'boolean' && !value) {
      return message;
    }
    if (value === undefined || value === null) {
      return message;
    }

    return;
  };

export const isRichTextRequired =
  (
    message = i18next.t('validatorMessages.isRequired', { ns: 'common' }) ||
      DEFAULT_ERROR_MESSAGE
  ) =>
  (value?: string) => {
    if (isEmptyRichTextValue(value)) {
      return message;
    }

    return;
  };

export const min =
  <ValueType = number>(
    minVal: number,
    message = i18next.t('validatorMessages.min', { minVal, ns: 'common' }) ||
      DEFAULT_ERROR_MESSAGE
  ) =>
  (value: ValueType) => {
    let transformedValue: ValueType | number | Decimal = value;

    // Because the decimal input returns a string :(
    // If you use min with the string, it's probably you're working with a number as a string
    // So there won't be any conflicts by doing this
    if (typeof value === 'string') {
      transformedValue = parseDecimal(value);
    }

    if (typeof transformedValue === 'number' && transformedValue < minVal) {
      return message;
    } else if (
      transformedValue instanceof Decimal &&
      transformedValue.lessThan(minVal)
    ) {
      return message;
    }

    return;
  };
export const greaterThan =
  <ValueType = number>(
    minVal: number,
    message = i18next.t('validatorMessages.greaterThan', {
      minVal,
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: ValueType) => {
    let transformedValue: ValueType | number | Decimal = value;

    // Because the decimal input returns a string :(
    // If you use greaterThan with the string, it's probably you're working with a number as a string
    // So there won't be any conflicts by doing this
    if (typeof value === 'string') {
      transformedValue = parseDecimal(value);
    }

    if (typeof transformedValue === 'number' && transformedValue <= minVal) {
      return message;
    } else if (
      transformedValue instanceof Decimal &&
      transformedValue.lessThanOrEqualTo(minVal)
    ) {
      return message;
    }

    return;
  };

export const greaterThanOrEqual =
  <ValueType = number>(
    minVal: number,
    message = i18next.t('validatorMessages.greaterThanOrEqual', {
      minVal,
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: ValueType) => {
    let transformedValue: ValueType | number | Decimal = value;

    // Because the decimal input returns a string :(
    // If you use greaterThan with the string, it's probably you're working with a number as a string
    // So there won't be any conflicts by doing this
    if (typeof value === 'string') {
      transformedValue = parseDecimal(value);
    }

    if (typeof transformedValue === 'number' && transformedValue < minVal) {
      return message;
    } else if (
      transformedValue instanceof Decimal &&
      transformedValue.lessThan(minVal)
    ) {
      return message;
    }

    return;
  };

export const isAtLeastLength =
  (
    n: number,
    message = i18next.t('validatorMessages.isAtLeastLength', {
      n,
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string | unknown[]) => {
    if (typeof value === 'string' && value.trim().length < n) {
      return message;
    }

    if (value?.length < n) {
      return message;
    }

    return;
  };
export const isRichTextAtLeastLength =
  (
    n: number,
    message = i18next.t('validatorMessages.isAtLeastLength', {
      n,
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string) => {
    if (getRichEditorStrippedValue(value)?.length < n) {
      return message;
    }

    return;
  };

export const isAtMostLength =
  (
    n: number,
    message = i18next.t('validatorMessages.isAtMostLength', {
      n,
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string) => {
    if (value?.length > n) {
      return message;
    }

    return;
  };
export const isRichTextAtMostLength =
  (
    n: number,
    message = i18next.t('validatorMessages.isAtMostLength', {
      n,
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string) => {
    if (getRichEditorStrippedValue(value)?.length > n) {
      return message;
    }

    return;
  };

export const shouldHaveAtLeast =
  <ValueType>(
    n: number,
    message = i18next.t('validatorMessages.isAtLeastLength', {
      n,
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: Array<ValueType> | undefined) => {
    if (!value || value.length < n) {
      return message;
    }

    return;
  };

export const isEmail =
  (
    message = i18next.t('validatorMessages.isEmail', { ns: 'common' }) ||
      DEFAULT_ERROR_MESSAGE,
    trimValue = false
  ) =>
  (value: string) => {
    if (!value) {
      return message;
    }
    const val = trimValue ? value.trim() : value;

    if (!validator.isEmail(cleanEmail(val))) {
      return message;
    }

    return;
  };

export const isEmailList =
  (
    message = () =>
      i18next.t('validatorMessages.isEmailList', { ns: 'common' }) ||
      DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string[]) => {
    if (!value.length) {
      return;
    }

    const emails = value.map((str) => str.trim());

    const emailErrors = emails.filter(
      (email) => email && !validator.isEmail(cleanEmail(email))
    );

    if (emailErrors.length) {
      return message();
    }

    return;
  };

export const isPhone =
  ({
    message = (i18next.t('validatorMessages.isPhone', {
      ns: 'common',
    }) as string) || DEFAULT_ERROR_MESSAGE,
    isOptional = false,
  } = {}) =>
  ({ country, telephone }: { country: CountryCode; telephone: string }) => {
    if (!telephone) {
      return isOptional ? undefined : message;
    }
    if (!isValidNumberForRegion(telephone, country)) {
      return message;
    }

    return;
  };

export const isIban =
  (
    message = i18next.t('validatorMessages.isIban', { ns: 'common' }) ||
      DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string) => {
    if (!value) {
      return message;
    }
    if (!validator.isIBAN(value)) {
      return message;
    }

    return;
  };

export const isCardNumber =
  (
    message = i18next.t('validatorMessages.isCardNumber', { ns: 'common' }) ||
      DEFAULT_ERROR_MESSAGE
  ) =>
  (cardNumber: number | string) => {
    const input =
      typeof cardNumber === 'string' ? cardNumber : cardNumber.toString();
    const number = input.replaceAll(' ', '');

    const isValid = isValidCardNumber(number);

    if (!isValid) {
      return message;
    }

    return;
  };

export const isSepa =
  (
    message = i18next.t('validatorMessages.isSepa', { ns: 'common' }) ||
      DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string) => {
    if (value && !SEPA_LIST.includes(value.slice(0, 2))) {
      return message;
    }

    return;
  };

export const isDateBefore =
  (
    ref: string | undefined | null,
    message = i18next.t('validatorMessages.isDateBefore', {
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE,
    inclusive = false,
    inclusiveGranularity: OpUnitType = 'milliseconds'
  ) =>
  (value: string | undefined | null) => {
    if (!value || !ref) {
      return;
    }
    const refValue = utilsDate.parseStringToDayJS(ref);
    const dateValue = utilsDate.parseStringToDayJS(value);

    if (!refValue.isValid()) {
      return;
    }

    if (inclusive && dateValue.isSame(refValue, inclusiveGranularity)) {
      return;
    }

    if (!dateValue.isValid() || !dateValue.isBefore(refValue)) {
      return message;
    }

    return;
  };
export const isDateAfter =
  (
    ref: string | undefined | null,
    message = i18next.t('validatorMessages.isDateAfter', {
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE,
    inclusive = false,
    inclusiveGranularity: OpUnitType = 'milliseconds'
  ) =>
  (value: string | undefined | null) => {
    if (!value || !ref) {
      return;
    }
    const refValue = utilsDate.parseStringToDayJS(ref);
    const dateValue = utilsDate.parseStringToDayJS(value);

    if (!refValue.isValid()) {
      return;
    }

    if (inclusive && dateValue.isSame(refValue, inclusiveGranularity)) {
      return;
    }

    if (!dateValue.isValid() || !dateValue.isAfter(refValue)) {
      return message;
    }

    return;
  };

export const isDateBetween =
  (
    min: Date,
    max: Date,
    message: string = i18next.t('validatorMessages.isDateBetween', {
      min: min.toLocaleDateString(),
      max: max.toLocaleDateString(),
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE,
    mask?: string
  ) =>
  (value: string | undefined | null) => {
    if (!value) {
      return;
    }

    const dateValue = utilsDate.parseStringToDayJS(value, mask);

    if (!dateValue.isValid() || !dateValue.isBetween(min, max)) {
      return message;
    }

    return;
  };

export const isPasswordValid =
  (
    message = i18next.t('validatorMessages.isPasswordValid', {
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string) => {
    if (!passwordSchema.validate(value)) {
      return message;
    }
    return;
  };

export const isEqualTo =
  (
    comparedValue: string | number,
    message = i18next.t('validatorMessages.isEqualTo', {
      ns: 'common',
      value: comparedValue,
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string | number) => {
    if (value !== comparedValue) {
      return message;
    }
    return;
  };
export const isNotEqualTo =
  (
    comparedValue: string | number,
    message = i18next.t('validatorMessages.isNotEqualTo', {
      ns: 'common',
      value: comparedValue,
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: string | number) => {
    if (value === comparedValue) {
      return message;
    }
    return;
  };

export const isUrl =
  <ValueType = string>(
    message = i18next.t('validatorMessages.isUrl', { ns: 'common' }) ||
      DEFAULT_ERROR_MESSAGE,
    requireProtocol = true
  ) =>
  (value: ValueType) => {
    if (
      value &&
      typeof value === 'string' &&
      !validator.isURL(value, {
        protocols: ['http', 'https'],
        require_protocol: requireProtocol,
      })
    ) {
      return message;
    }

    return;
  };

export const shouldMatch =
  <ValueType = string>(
    rule: RegExp,
    message = i18next.t('validatorMessages.isInvalid', {
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: ValueType) => {
    if (typeof value === 'string' && !value.match(rule)) {
      return message;
    }

    return;
  };

export const shouldNotMatch =
  <ValueType = string>(
    rule: RegExp,
    message = i18next.t('validatorMessages.isInvalid', {
      ns: 'common',
    }) || DEFAULT_ERROR_MESSAGE
  ) =>
  (value: ValueType) => {
    if (typeof value === 'string' && value.match(rule)) {
      return message;
    }

    return;
  };
